var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      pills: "",
                      vertical: "",
                      "nav-class": "p-0",
                      "nav-wrapper-class": "col-sm-2",
                      "content-class": "pt-0 px-2 text-muted"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Entreprise",
                          active: "",
                          "title-item-class": "mb-2"
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-lg-12" }, [
                            _c("div", {}, [
                              _c("div", {}, [
                                _c(
                                  "form",
                                  {
                                    staticClass: "needs-validation",
                                    attrs: { enctype: "multipart/form-data" },
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.submitCompanySettings($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-8" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "formrow-inputCity"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Nom d'entreprise *"
                                                      )
                                                    ]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .companySettingsForm
                                                            .company_name,
                                                        expression:
                                                          "companySettingsForm.company_name"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.submitted &&
                                                        _vm.$v
                                                          .companySettingsForm
                                                          .company_name.$error
                                                    },
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.companySettingsForm
                                                          .company_name
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.companySettingsForm,
                                                          "company_name",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm.submitted &&
                                                  _vm.$v.companySettingsForm
                                                    .company_name.$error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback"
                                                        },
                                                        [
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_name
                                                            .required
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Champs obligatoire."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_name
                                                            .minLength
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Le nom d'entreprise doit comprendre plus de 3 caractères."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br")
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "formrow-inputCity"
                                                      }
                                                    },
                                                    [_vm._v("Email *")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .companySettingsForm
                                                            .company_email,
                                                        expression:
                                                          "\n                                      companySettingsForm.company_email\n                                    "
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.submitted &&
                                                        _vm.$v
                                                          .companySettingsForm
                                                          .company_email.$error
                                                    },
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.companySettingsForm
                                                          .company_email
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.companySettingsForm,
                                                          "company_email",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm.submitted &&
                                                  _vm.$v.companySettingsForm
                                                    .company_email.$error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback"
                                                        },
                                                        [
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_email
                                                            .required
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Champs obligatoire."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_email.email
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Email Invalide."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br")
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-md-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for:
                                                          "formrow-password-input"
                                                      }
                                                    },
                                                    [_vm._v("N° Telephone")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .companySettingsForm
                                                            .company_phone,
                                                        expression:
                                                          "\n                                      companySettingsForm.company_phone\n                                    "
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      "is-invalid":
                                                        _vm.submitted &&
                                                        _vm.$v
                                                          .companySettingsForm
                                                          .company_phone.$error
                                                    },
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.companySettingsForm
                                                          .company_phone
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.companySettingsForm,
                                                          "company_phone",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm.submitted &&
                                                  _vm.$v.companySettingsForm
                                                    .company_phone.$error
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "invalid-feedback"
                                                        },
                                                        [
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_phone
                                                            .required
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Champs obligatoire."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          !_vm.$v
                                                            .companySettingsForm
                                                            .company_phone
                                                            .numeric
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Champ Invalide."
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          _c("br")
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "formrow-inputCity"
                                                      }
                                                    },
                                                    [_vm._v("Site web")]
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .companySettingsForm
                                                            .company_website,
                                                        expression:
                                                          "\n                                      companySettingsForm.company_website\n                                    "
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        _vm.companySettingsForm
                                                          .company_website
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.companySettingsForm,
                                                          "company_website",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _vm._v(" matchUrl ")
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-12" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "formrow-inputCity"
                                                      }
                                                    },
                                                    [_vm._v("Adresse *")]
                                                  ),
                                                  _c("textarea", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .companySettingsForm
                                                            .company_adr,
                                                        expression:
                                                          "companySettingsForm.company_adr"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      cols: "30",
                                                      rows: "4"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.companySettingsForm
                                                          .company_adr
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.companySettingsForm,
                                                          "company_adr",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "col-md-4" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formrow-inputCity"
                                                }
                                              },
                                              [_vm._v("Image/Logo ")]
                                            ),
                                            _c("input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "file",
                                                accept: "image/*"
                                              },
                                              on: {
                                                change: _vm.onLogoSelectChange
                                              }
                                            }),
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showPreview,
                                                  expression: "showPreview"
                                                }
                                              ],
                                              attrs: {
                                                src: _vm.imagePreview,
                                                width: "100",
                                                height: "100"
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("hr"),
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-md-12" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary float-right"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-save"
                                            }),
                                            _vm._v(" Enregistrer ")
                                          ]
                                        )
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        attrs: {
                          title: "Etats de sortie",
                          "title-item-class": "mb-2"
                        }
                      },
                      [
                        _c(
                          "form",
                          {
                            staticClass: "needs-validation",
                            attrs: { enctype: "multipart/form-data" },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.formSubmit($event)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "h4",
                                        {
                                          class: {
                                            "text-danger":
                                              _vm.submitted &&
                                              _vm.$v.reportsSettingsForm
                                                .report_paper_head.$error
                                          }
                                        },
                                        [_vm._v("Entête de page *")]
                                      ),
                                      _c("p", {}, [
                                        _vm._v(
                                          " Cette partie sera affichée comme "
                                        ),
                                        _c("b", [_vm._v("entête de page")]),
                                        _vm._v(" sur tous les rapports. ")
                                      ]),
                                      _c("ckeditor", {
                                        class: {
                                          "is-invalid":
                                            _vm.submitted &&
                                            _vm.$v.reportsSettingsForm
                                              .report_paper_head.$error
                                        },
                                        attrs: { editor: _vm.editor },
                                        model: {
                                          value:
                                            _vm.reportsSettingsForm
                                              .report_paper_head,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.reportsSettingsForm,
                                              "report_paper_head",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "reportsSettingsForm.report_paper_head"
                                        }
                                      }),
                                      _vm.submitted &&
                                      _vm.$v.reportsSettingsForm
                                        .report_paper_head.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              !_vm.$v.reportsSettingsForm
                                                .report_paper_head.required
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Champs obligatoire."
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "h4",
                                        {
                                          class: {
                                            "text-danger":
                                              _vm.submitted &&
                                              _vm.$v.reportsSettingsForm
                                                .report_paper_foot.$error
                                          }
                                        },
                                        [_vm._v("Pied de page *")]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "card-title-desc" },
                                        [
                                          _vm._v(
                                            " Cette partie sera affichée comme "
                                          ),
                                          _c("b", [_vm._v("pied de page")]),
                                          _vm._v(" sur tous les rapports. ")
                                        ]
                                      ),
                                      _c("ckeditor", {
                                        class: {
                                          "is-invalid":
                                            _vm.submitted &&
                                            _vm.$v.reportsSettingsForm
                                              .report_paper_foot.$error
                                        },
                                        attrs: { editor: _vm.editor },
                                        model: {
                                          value:
                                            _vm.reportsSettingsForm
                                              .report_paper_foot,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.reportsSettingsForm,
                                              "report_paper_foot",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "reportsSettingsForm.report_paper_foot"
                                        }
                                      }),
                                      _vm.submitted &&
                                      _vm.$v.reportsSettingsForm
                                        .report_paper_foot.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              !_vm.$v.reportsSettingsForm
                                                .report_paper_foot.required
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Champs obligatoire."
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _c("br")
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ]),
                            _c("hr"),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary float-right"
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-save" }),
                                    _vm._v(" Enregistrer ")
                                  ]
                                )
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }